<template>
    <b-modal id="modal" size="md" :title="data.name"
             @close="onReset()"
             no-close-on-esc
             no-close-on-backdrop >
        
        <b-form id="form1" @reset="onReset" @submit="onSubmit" v-if="data">
            <div v-if="data.editable" class="row" >
                <b-form-group class="col-12">
                    <label>Url</label>
                    <b-input-group>
                        <b-form-input v-model="data.url" readonly></b-form-input>
                        <b-input-group-append is-text>
                            <small class="mr-2">Vygenerovat nové url z názvu?</small>
                            <b-form-checkbox switch class="mr-n2" v-model="data.regenerate_url">
                                <span class="sr-only">Vygenerovat nové url z názvu?</span>
                            </b-form-checkbox>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <b-tabs content-class="mb-2 mt-2" class="c-tabs-lang" v-bind:class="{ 'c-tabs-lang--single-lang' : langs.length === 1 }">
                <b-tab v-for="lang in langs" :key="'lang_'+lang.id" :title="lang.name" >
                    
                    <template v-for="(seo_lang, index) in data.pages_seo" >
                        
                        
                        <b-form-group v-if="lang.id === seo_lang.id_lang" :key="'lang_name_'+seo_lang.id">
                            <label>Název</label>
                            <b-form-input
                                v-model="seo_lang.name"
                                type="text"
                                :required="index == 0"
                            ></b-form-input>
                        </b-form-group>
                        <!--
                        <b-form-group v-if="lang.id === seo_lang.id_lang" :key="'seo_lang_title_'+seo_lang.id" >
                            <label>Titulek (Seo)</label>
                            <b-form-input
                                v-model="seo_lang.seo_title"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                        
                        <b-form-group v-if="lang.id === seo_lang.id_lang" :key="'seo_lang_description_'+seo_lang.id">
                            <label>Popis (Seo)</label>
                            <b-form-textarea
                                v-model="seo_lang.seo_description"
                            ></b-form-textarea>
                        </b-form-group>
                    -->
                    </template>
                    
                    <hr>
                
                </b-tab>
            </b-tabs>
            
            <b-row>
                
                <b-form-group class="col-12">
                    <label>Obrázek (Seo)</label>
                    <b-form-input
                        v-model="data.seo_img"
                        type="text"
                    ></b-form-input>
                </b-form-group>
            </b-row>
            
            <div v-if="data.editable" class="row">
                <b-form-group class="col-12 text-right">
                    <b-button variant="danger" @click="deleteItem(data.id)">{{ $t('universalTxt.delete') }}
                        <b-icon icon="trash-fill" ></b-icon>
                    </b-button>
                </b-form-group>
            </div>
        
        </b-form>
        <template v-slot:modal-footer>
            <div class="w-100">
                <b-button type="reset" class="float-left" form="form1" variant="outline-secondary">
                  {{ $t('universalTxt.closeBtn') }}
                </b-button>
                <b-button type="submit" class="float-right" form="form1" variant="primary">
                  {{ $t('universalTxt.saveBtn') }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

//import {getUrl} from '@/mixins/lib.js';

export default {
    name: "pages-edit",
    data() {
        return {
            data: {}
        }
    },
    methods: {
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/pages/pages.php',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    params: {'id': this.$route.params.id },
                    method: 'GET'
                });
                
                this.data = response.data.records[0];
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', 'Žádná data');
            }
            
        },
        async onSubmit(e) {
            e.preventDefault();
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/pages/update-item.php',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data: this.data,
                    method: 'PUT'
                });
                
                this.$emit('loadData');
                this.$bvModal.hide('modal');
                this.$router.go(-1);
                this.$eventHub.$emit('openMessageController', 'success', 'Změna proběhla úspěšně');
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', 'Operace se nezdařila');
            }
            
        },
        async deleteItem(id) {
            try {
                if (confirm(this.$t('projects.deleteProject'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/pages/remove-item.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {'id': id},
                        method: 'DELETE'
                    });
                    this.$emit('loadData');
                    this.$bvModal.hide('modal');
                    this.$router.go(-1);
                    this.$eventHub.$emit('openMessageController', 'success', 'Položka byla smazána');
                } else {
                    this.$router.go(-1);
                    this.$emit('loadData');
                }
            } catch (error) {
                await this.loadData();
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        onReset () {
            if (this.data.new == 1) {
                this.deleteItem(this.data.id);
            } else {
                this.$router.go(-1);
                this.$emit('loadData');
            }
        }
        
    },
    computed: {
        langs () {
            return this.$store.getters.langs
        }
    },
    mounted() {
        this.$bvModal.show('modal');
        this.loadData();
    },
    created() {
    
    }
}
</script>

<style scoped >

</style>
